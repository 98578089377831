require('normalize.css/normalize.css');
require('../css/index.scss');

import { getProName, navigatePro, clearText } from './utils.js';
import { Loader } from '@googlemaps/js-api-loader';

const locationContainer = document.getElementById('location_container');
const locationSearch = document.getElementById('location_search');
const categoryContainer = document.getElementById('category_container');
const backButton = document.getElementById('back_button');

const params = new URLSearchParams(location.search);
const proLanding = params.get('proLanding');

if (proLanding) {
  const name = getProName(proLanding);
  const whereHeader = document.getElementById('where_header');
  whereHeader.innerHTML = 'Where are you looking for ' + name + "?";

  categoryContainer.classList.toggle('hide');
  locationContainer.classList.toggle('show');
  backButton.classList.toggle('hide');
}

document.getElementById('button_container').addEventListener('click', (event) => {
  if (event.target.closest('input') !== null) {

    const category = document.querySelector('input[name=radio-category]:checked').getAttribute('data-id');
    const name = getProName(category);

    const whereHeader = document.getElementById('where_header');
    whereHeader.innerHTML = 'And where are you looking for ' + name + "?";

    categoryContainer.classList.toggle('animate');
    locationContainer.classList.remove('hide');
    locationContainer.classList.toggle('animate');
  }
});

backButton.addEventListener('click', (_) => {
  categoryContainer.classList.toggle('animate');
  locationContainer.classList.toggle('animate');
  clearText(locationSearch);
});

const loader = new Loader({
  apiKey: 'AIzaSyDPRMuc-vQprevDs0T98KYsk2Wj_ytM0gM',
  libraries: ['places'],
  region: 'us',
});

loader
  .load()
  .then(() => {
    const options = {
      componentRestrictions: { country: ['us'] },
      types: ['geocode'],
    };
    const autocomplete = new google.maps.places.Autocomplete(locationSearch, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const radioChecked= document.querySelector('input[name=radio-category]:checked');
      const category = radioChecked ? radioChecked.getAttribute('data-id') : params.get('proLanding');
      
      const place = autocomplete.getPlace();
      const search = place.formatted_address;
      let city; let
        state;

      place.address_components.forEach((component) => {
        if (component.types.includes('sublocality') || component.types.includes('locality')) {
          city = component.short_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name;
        }

        navigatePro(search, state, city, category, 'quote');
      });
    });
  })
  .catch((e) => {
    console.log(e);
  });
