export function navigatePro(search, state, city, category, action) {
  const urlParams = `search=${search}&state=${state}&city=${city}${action ? `&action=${action}` : ''}`;
  if (category) {
    window.location = `${process.env.HOME_BUYER_GUIDE_DASHBOARD_URL}location/professionals/${category}?${urlParams}`;
  } else {
    window.location = `${process.env.HOME_BUYER_GUIDE_DASHBOARD_URL}location/professionals?${urlParams}`;
  }
}

export function navigateGuide(search, state, city, category) {
  const urlParams = `search=${search}&state=${state}&city=${city}`;
  if (category) {
    window.location = `${process.env.HOME_BUYER_GUIDE_DASHBOARD_URL}location/guide/${category}?${urlParams}`;
  } else {
    window.location = `${process.env.HOME_BUYER_GUIDE_DASHBOARD_URL}location/guide?${urlParams}`;
  }
}

export function clearText(textBox) {
  textBox.value = '';
}

export function getProSvg(dataId) {
  switch (dataId) {
    case 'MortgageBrokers':
      return ['#mortgage-brokers', 'Mortgage<br />Lenders'];
    case 'RealEstateAgents':
      return ['#real-estate', 'Real Estate<br />Agents'];
    case 'Attorneys':
      return ['#attorneys', 'Attorneys'];
    case 'HomeInspectors':
      return ['#home-inspectors', 'Home<br />Inspectors'];
    case 'HomeownersInsurance':
      return ['#home-insurance', 'Home<br />Insurance'];
    case 'Movers':
      return ['#movers', 'Moving<br />Companies'];
  }
}

export function getProName(dataId) {
  switch (dataId) {
    case 'MortgageBrokers':
      return 'a Mortgage Lender';
    case 'RealEstateAgents':
      return 'a Real Estate Agent';
    case 'Attorneys':
      return 'an Attorney';
    case 'HomeInspectors':
      return 'a Home Inspector';
    case 'HomeownersInsurance':
      return 'an Insurance Broker';
    case 'Movers':
      return 'a Moving Company';
  }
}

export function getGuideSvg(dataId) {
  switch (parseInt(dataId)) {
    case 1:
      return ['#pre-approved', 'Get<br />Pre-Approved'];
    case 2:
      return ['#find-property', 'Find a<br />Property'];
    case 3:
      return ['#make-offer', ' Make an<br />Offer'];
    case 4:
      return ['#home-inspectors', 'Home<br />Inspection'];
    case 5:
      return ['#sign-contract', 'Sign<br />Contract'];
    case 6:
      return ['#home-insurance', 'Get<br />Insurance'];
    case 7:
      return ['#mortgage-brokers', 'Mortgage<br />Application'];
    case 8:
      return ['#walkthrough', 'Final<br />Walkthrough'];
    case 9:
      return ['#real-estate', 'Closing'];
    case 10:
      return ['#movers', 'Move'];
  }
}
